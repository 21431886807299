import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useMobDataDailyDistance from '@/hooks/fetch/useMobDataDailyDistance'
import CountUp from 'react-countup'
import styled from 'styled-components'
import {
  STYLE_FONT_SIZE,
  STYLE_FONT_WEIGHT,
  STYLE_LINE_HEIGHT,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { ComponentStyledOptionalProps } from '@/types/Component'

/**
 * @see 아래
 * 2021.2.19
 * 카카오 T 누적 이용자 수 임시 비노출 처리를 위해 제거
 * CountUpViewer 로 이 컴포넌트 사용한 곳을 대체해서 복구 필요
 */
function CountUpViewerOnlyDistance({
  className,
}: ComponentStyledOptionalProps) {
  const { data: dailyDistanceResponse } = useMobDataDailyDistance()

  const [distanceTitle, distanceValue, distanceUnit] = useMemo(() => {
    return [
      '오늘 하루의 누적 이동거리',
      dailyDistanceResponse?.value ?? 0,
      dailyDistanceResponse?.unit ?? 'km',
    ]
  }, [dailyDistanceResponse])

  const [value, setValue] = useState(distanceValue)

  const formattingFn = useCallback((value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }, [])

  useEffect(() => {
    if (!distanceValue) {
      return
    }
    setValue(distanceValue)
  }, [distanceValue])

  return (
    <Styled.Wrap className={className}>
      <Styled.TitleWrap>
        <Styled.Title>{distanceTitle}</Styled.Title>
      </Styled.TitleWrap>

      <Styled.Number>
        <CountUp
          start={value / 2}
          end={value}
          formattingFn={formattingFn}
          duration={5}
        />
      </Styled.Number>
      <Styled.Unit>{distanceUnit}</Styled.Unit>
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div`
    text-align: right;
    color: ${STYLE_COLOR.WHITE01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      text-align: left;
    }
  `,
  Number: styled.strong`
    display: inline-block;
    font-size: ${STYLE_FONT_SIZE._72};
    line-height: ${STYLE_LINE_HEIGHT._92};
    font-weight: ${STYLE_FONT_WEIGHT.EXTRA_BOLD};
    vertical-align: middle;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      height: ${STYLE_LINE_HEIGHT._56};
      font-size: ${STYLE_FONT_SIZE._40};
      line-height: ${STYLE_LINE_HEIGHT._56};
    }
  `,
  TitleWrap: styled.span`
    position: relative;
    display: block;
    overflow: hidden;
    height: ${STYLE_LINE_HEIGHT._34};
    font-size: ${STYLE_FONT_SIZE._22};
    line-height: ${STYLE_LINE_HEIGHT._34};
    font-weight: ${STYLE_FONT_WEIGHT.EXTRA_BOLD};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      height: ${STYLE_LINE_HEIGHT._28};
      font-size: ${STYLE_FONT_SIZE._18};
      line-height: ${STYLE_LINE_HEIGHT._28};
    }
    &::before {
      content: '';
      display: block;
      transition: margin 0.25s ease;
    }
  `,
  Title: styled.span`
    display: block;
  `,
  Unit: styled.em`
    display: inline-block;
    overflow: hidden;
    margin-top: ${STYLE_FONT_SIZE._16};
    vertical-align: middle;
    height: ${STYLE_LINE_HEIGHT._46};
    font-size: ${STYLE_FONT_SIZE._30};
    line-height: ${STYLE_LINE_HEIGHT._46};
    font-weight: ${STYLE_FONT_WEIGHT.EXTRA_BOLD};
    font-style: normal;
    text-align: left;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      height: ${STYLE_LINE_HEIGHT._34};
      font-size: ${STYLE_FONT_SIZE._22};
      line-height: ${STYLE_LINE_HEIGHT._34};
    }
  `,
}

export default CountUpViewerOnlyDistance
