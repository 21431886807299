import { callJsonContentApi } from '@/apis/jsonData'
import { getNotices } from '@/apis/noticeData'
import ContentConverter from '@/components/ContentConverter'
import DashboardVideo from '@/components/DashboardVideo'
import Intro from '@/components/Intro'
import Layout from '@/components/layouts/Layout'
import { ApiPath } from '@/constants/Path'
import useAppContext from '@/contexts'
import { withMetaContent } from '@/helpers/withMetaContent'
import { Contents } from '@/types/Content'
import React, { Fragment } from 'react'
import { unstable_serialize } from 'swr/infinite'

export interface IndexProps {
  content: Contents
}

function Index() {
  const { config } = useAppContext()
  const IntroCover = config.intro ? Fragment : Intro

  return (
    <>
      <Layout>
        <DashboardVideo play={config.playIntroVideo} />
        <ContentConverter pathSegments={['main']} />
      </Layout>
      <IntroCover />
    </>
  )
}

export default Index

export const getServerSideProps = withMetaContent(async () => {
  const [resultContent, resultNews] = await Promise.all([
    callJsonContentApi({ contentId: 'main' }),
    getNotices({
      notice_types: ['NEWS', 'MORE'],
      size: 3,
      offset: 0,
    }),
  ])

  return {
    props: {
      fallback: {
        [unstable_serialize(() =>
          ApiPath.GET_NOTICES({
            notice_types: ['NEWS', 'MORE'],
            size: 3,
            offset: 0,
          })
        )]: resultNews.data,
        [ApiPath.CONTENT('main')]: resultContent.data,
      },
    },
  }
})
