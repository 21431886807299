import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { ImageAsset } from '@/constants/StaticAsset'
import useAppContext from '@/contexts'
import { STYLE_KEEP_WORD } from '@/styles/fonts'
import useMetaContent from '@/hooks/fetch/useMetaContent'

interface IntroElementProps {
  step: number
  time: number
}

const StepTimer = [1000, 800, 800, 500]

function Intro() {
  const { configAction } = useAppContext()
  const { meta } = useMetaContent()
  const slogan = meta.slogan

  const [timer, setTimer] = useState(null)
  const [step, setStep] = useState(0)
  const time = (StepTimer[step] ?? 1) / 1000

  useEffect(() => {
    const END_STEP = StepTimer.length
    if (!slogan) {
      return undefined
    }
    if (step === END_STEP - 2) {
      configAction.setPlayIntroVideo(true)
    }
    if (step < END_STEP && timer === null) {
      setTimer(
        setTimeout(() => {
          setStep((prevState) => prevState + 1)
          setTimer(null)
        }, StepTimer[step])
      )
      return undefined
    }
    if (step < END_STEP) {
      return undefined
    }
    configAction.setIntro(true)
  }, [configAction, step, timer, slogan])

  const elementProps = {
    step,
    time,
  }

  return (
    <Styled.Root {...elementProps}>
      <Styled.Fixer>
        <Styled.Line {...elementProps} />
        <Styled.Slogan {...elementProps}>{slogan}</Styled.Slogan>
        <Styled.Line {...elementProps} />
      </Styled.Fixer>
    </Styled.Root>
  )
}

const Styled = {
  Root: styled.div<IntroElementProps>`
    z-index: 9000;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${STYLE_COLOR.DARK_BG};
    font-size: 0;
    line-height: 0;
    white-space: nowrap;
    text-align: center;
    transition: all 0.5s 0.2s ease-out;
    &::before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    ${({ time, step }) => {
      switch (step) {
        case 0:
        case 1:
          return css`
            transition: all ${time}s ease-out;
            opacity: 1;
          `
        default:
          return css`
            transition: all ${time}s ease-out;
            opacity: 0;
          `
      }
    }};
  `,
  Fixer: styled.div`
    display: inline-block;
    width: 100%;
    margin-top: -14px;
    vertical-align: middle;
    text-align: center;
    ${STYLE_KEEP_WORD};
  `,
  Line: styled.div<IntroElementProps>`
    display: block;
    height: 40px;
    margin: 14px auto 0;
    background-color: ${STYLE_COLOR.YELLOW};
    border: 0 none;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-top: 0;
    }
    ${({ step }) => {
      switch (step) {
        case 0:
          return css`
            transition: all 2s ease-out;
            transform: translateX(-100%);
          `
        case 1:
          return css`
            transition: all 0.5s ease-out;
            transform: translateX(0);
          `
        default:
          return css`
            transition: all 0.5s ease-out;
            transform: translateX(100%);
          `
      }
    }};
  `,
  Slogan: styled.p<IntroElementProps>`
    width: 20%;
    margin: 14px auto 0;
    padding-bottom: 5%;
    background-image: url(${ImageAsset.SLOGAN});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      width: 80%;
      padding-bottom: 20%;
      margin-top: 0;
    }
    ${({ step }) => {
      switch (step) {
        case 0:
          return css`
            transition: all 0.5s ease-out;
            opacity: 0;
          `
        case 1:
          return css`
            transition: all 0.6s 0.2s ease-out;
            opacity: 1;
            transform: scale(1);
          `
        default:
          return css`
            transition: all 0.6s 0.2s ease-out;
            opacity: 0;
            transform: scale(1.1);
          `
      }
    }};
  `,
}

export default Intro
